import React, { Component } from 'react';
import { Step, TIME_UNITS, TIME_UNITS_ABBREVIATIONS } from '../../../../store/Step';
import { Grid, Container, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { t } from '../../../../i18n';
import StepTimePreview from './StepTimePreview';
import { TargetNumberInput } from '@code-yellow/spider';

interface StepProcessTimingProps {
    step: Step;
    disabled: boolean;
}

const ProcessTimingExplanationText = styled(Container)`
    background-color: #F5F5F5;
    padding: 16px;

`;

const OverlayDropdown = styled(Dropdown)`
    width: 67px;
    margin-left: -54px !important;
`;


const GridRowWithDoubleColumn = styled(Grid.Row)`
    // Don't change this.
  .fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent  {
    width:40% !important;
  }
`;

@observer
export default class StepProcessTiming extends Component<StepProcessTimingProps> {


    render() {
        const { step, disabled = false } = this.props;

        return (
            <Grid>
                <GridRowWithDoubleColumn columns={5}>
                    <Grid.Column>
                        <TargetNumberInput
                            label={t('step.field.delayMinutes.label')}
                            target={step}
                            name={'delayInCustomTimeUnit'}
                            onChange={(value) => {
                                step.setInput('delayInCustomTimeUnit', value || 0)
                                step.setInput('delayMinutes', step.delayInCustomUnitAsMinutes)
                            }}
                            disabled={disabled}
                            contentProps={{
                                labelPosition: 'right',
                                label: (
                                    <OverlayDropdown
                                        data-test-delay-unit-select
                                        value={!step.delayTimeUnit ? 'minutes' : step.delayTimeUnit}
                                        onChange={(e, { value }) => {
                                            step.setInput('delayTimeUnit', value)
                                            step.setInput('delayMinutes', step.delayInCustomUnitAsMinutes)
                                        }}
                                        options={TIME_UNITS.map((value) => ({ value, text: TIME_UNITS_ABBREVIATIONS[value] }))}
                                        disabled={disabled}
                                    />
                                ),
                            }}
                        />
                        <ProcessTimingExplanationText>
                            <p>
                                {t('step.field.delayMinutes.info')}
                            </p>
                            <p>
                                {t('step.field.delayMinutes.infoElaborate')}
                            </p>
                        </ProcessTimingExplanationText>
                    </Grid.Column>
                    <Grid.Column>
                        <TargetNumberInput
                            label={t('step.field.setupMinutes.label')}
                            target={step}
                            name={'setupInCustomTimeUnit'}
                            onChange={(value) => {
                                step.setInput('setupInCustomTimeUnit', value || 0)
                                step.setInput('setupMinutes', step.setupInCustomUnitAsMinutes)
                            }}
                            disabled={disabled}
                            contentProps={{
                                labelPosition: 'right',
                                label: (
                                    <OverlayDropdown
                                        data-test-setup-unit-select
                                        value={!step.setupTimeUnit ? 'minutes' : step.setupTimeUnit}
                                        onChange={(e, { value }) => {
                                            step.setInput('setupTimeUnit', value)
                                            step.setInput('setupMinutes', step.setupInCustomUnitAsMinutes)
                                        }}
                                        options={TIME_UNITS.map((value) => ({ value, text: TIME_UNITS_ABBREVIATIONS[value] }))}
                                        disabled={disabled}
                                    />
                                ),
                            }}
                        />
                        <ProcessTimingExplanationText>
                            <p>
                                {t('step.field.setupMinutes.info')}
                            </p>
                            <p>
                                {t('step.field.setupMinutes.infoElaborate')}
                            </p>
                        </ProcessTimingExplanationText>
                    </Grid.Column>
                    <Grid.Column>
                        <TargetNumberInput
                            label={t('step.field.workMinutes.label')}
                            target={step}
                            name={'workInCustomTimeUnit'}
                            onChange={(value) => {
                                step.setInput('workInCustomTimeUnit', value || 0)
                                step.setInput('workMinutes', step.workInCustomUnitAsMinutes)
                            }}
                            disabled={disabled}
                            contentProps={{
                                labelPosition: 'right',
                                label: (
                                    <OverlayDropdown
                                        data-test-work-unit-select
                                        value={!step.workTimeUnit ? 'minutes' : step.workTimeUnit}
                                        onChange={(e, { value }) => {
                                            step.setInput('workTimeUnit', value)
                                            step.setInput('workMinutes', step.workInCustomUnitAsMinutes)
                                        }}
                                        options={TIME_UNITS.map((value) => ({ value, text: TIME_UNITS_ABBREVIATIONS[value] }))}
                                        disabled={disabled}
                                    />
                                ),
                            }}
                        />
                        <ProcessTimingExplanationText>
                            <p>
                                {t('step.field.workMinutes.info')}
                            </p>
                            <p>
                                {t('step.field.workMinutes.infoElaborate')}
                            </p>
                        </ProcessTimingExplanationText>
                    </Grid.Column>
                    <Grid.Column width={6} className="fourtypercent">
                        <StepTimePreview
                            step={step}
                        />
                    </Grid.Column>
                </GridRowWithDoubleColumn>
            </Grid>
        )
    }
}

