import { SalesOrderLine } from '../../../store/SalesOrderLine';
import { NeededAllocation } from './index';
import { BillOfMaterialVersion, BillOfMaterialVersionStore } from '../../../store/BillOfMaterialVersion';
import { BillOfMaterialItemStore } from '../../../store/BillOfMaterialItem';
import Decimal from 'decimal.js';
import { ArticleType } from '../../../store/ArticleType';


export function getAssemblyBom(salesOrderLine: SalesOrderLine): BillOfMaterialVersion | null {
    // @ts-ignore
    const bomVersions: BillOfMaterialVersionStore = salesOrderLine.articleType.billOfMaterialVersions;

    // @ts-ignore
    return bomVersions.find((bomVersion: BillOfMaterialVersion) => {
        return bomVersion.type === 'assembly_bom';
    })

    return null;
}

/**
 * For a Assembly Item, 1heck how mcuh we have to
 * @param salesOrderLine
 */
export function getNeededAllocationsForAssemblyItemSalesOrderLine(salesOrderLine: SalesOrderLine): NeededAllocation[] {

    const assemblyBom = getAssemblyBom(salesOrderLine);


    if (!assemblyBom) {
        return []
    }

    // @ts-ignore
    const bomItems: BillOfMaterialItemStore = assemblyBom.items;

    return bomItems.map(
        (billOfMaterialItem) => {
            // @ts-ignore
            const articleType: ArticleType = billOfMaterialItem.articleType;

            // @ts-ignore
            const itemQuantity: Decimal = new Decimal(billOfMaterialItem.quantity)

            return {
                amountNeeded: itemQuantity.mul(salesOrderLine.quantity),
                salesOrderLine: salesOrderLine,
                articleType: articleType
            }
        }
    )
}

/**
 *
 * @param salesOrderLine
 */
export function canAutoAllocateAssemblyItem(salesOrderLine: SalesOrderLine): boolean {
    // If every bit of unallocated stock for all the bom items in the same warehouse is enough to assemble at least 1 of the salesorderline's articleType
    return getNeededAllocationsForAssemblyItemSalesOrderLine(salesOrderLine).every(neededAllocation => {
        const articleType = neededAllocation.articleType
        const amount = neededAllocation.amountNeeded.toNumber()/salesOrderLine.quantity.toNumber()
        // @ts-ignore
        const articleTypeWarehouse = articleType.warehouses?.models?.filter(articleTypeWarehouse => articleTypeWarehouse.warehouse.id === salesOrderLine.salesOrder?.warehouse?.id)[0]
        return articleTypeWarehouse ? articleTypeWarehouse.unallocatedStock/amount >= 1 : false
    })
}

/**
 *
 * @param salesOrderLine
 * @param toBeAllocated
 */
export function canStockAllocateBomItem(salesOrderLine: SalesOrderLine, toBeAllocated: ArticleType): boolean {
    const neededAllocation = getNeededAllocationsForAssemblyItemSalesOrderLine(salesOrderLine).find(info => info.articleType.id === toBeAllocated.id)
    if (!neededAllocation) {
        return false
    }

    const amountNeeded: number = neededAllocation.amountNeeded.toNumber()
    const amountPerArticle: number = amountNeeded/salesOrderLine.quantity.toNumber()
    const articleType = neededAllocation.articleType

    // @ts-ignore
    const articleTypeWarehouse = articleType.warehouses?.models?.filter(articleTypeWarehouse => articleTypeWarehouse.warehouse.id === salesOrderLine.salesOrder?.warehouse?.id)[0]
    return articleTypeWarehouse ? articleTypeWarehouse.unallocatedStock/amountPerArticle >= 1 : false
}
