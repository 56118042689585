import { observer } from 'mobx-react';
import { action, reaction } from 'mobx';
import React from 'react';
import { ProductionLineStore } from '../store/ProductionLine';
import { TargetBase, TargetSelect, TargetTextInput } from '@code-yellow/spider';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';


const WorkStationGroup = styled(Form.Group)`
  > .field:nth-child(1) {
    padding-right: 0 !important;
    > .ui.selection.dropdown {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  > .field:nth-child(2) {
    padding-left: 0 !important;
    > .ui.selection.dropdown {
      min-width: unset !important;
      border-left-width: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`


@observer
export default class WorkStationSelect extends TargetBase {
  static propTypes = {
    ...TargetSelect.propTypes,
  }

  productionLineStore = new ProductionLineStore({
    relations: ['versions.workStations'],
    params: { '.type': 'production' },
  })

  componentDidMount() {
    this.defaultProductionLineVersionReaction = reaction(
      () => JSON.stringify([this.value.productionLineVersion.isNew, this.props.defaultProductionLineVersion?.id]),
      (data) => {
        const { target, name, defaultProductionLineVersion } = this.props
        if (this.value.productionLineVersion.isNew && defaultProductionLineVersion) {
          target.setInput(name, { toJS: () => ({ productionLineVersion: defaultProductionLineVersion.toJS() }) })
        }
      },
      { fireImmediately: true },
    )
    this.factoryReaction = reaction(
      () => this.props.factory.id,
      action((factoryId) => {
        if (factoryId === null) {
          delete this.productionLineStore.params['.factory']
        } else {
          this.productionLineStore.params['.factory'] = factoryId
        }
        this.productionLineStore.clear()
      }),
    )
  }

  componentWillUnmount() {
    this.defaultProductionLineVersionReaction()
    this.factoryReaction()
  }

  renderContent() {
    const { target, name, disabled, factory } = this.props
    return (
      <WorkStationGroup>
        <TargetSelect noLabel remote
          data-test-production-line-select
          width="6"
          value={this.value.productionLineVersion.productionLine.id}
          onChange={(productionLineId) => {
            const productionLine = this.productionLineStore.get(productionLineId) || null
            const productionLineVersion = (productionLine && productionLine.versions.find(({ newest }) => newest)) || null
            target.setInput(name, productionLine && { toJS: () => ({
              productionLineVersion: {
                ...productionLineVersion ? productionLineVersion.toJS() : {},
                productionLine: productionLine.toJS(),
              },
            }) })
          }}
          store={this.productionLineStore}
          toOption={(productionLine) => ({
            value: productionLine.id,
            text: productionLine.name,
          })}
          searchKey=".name:icontains"
          disabled={!factory || disabled}
          name="productionLine"
        />
        <TargetSelect noLabel
          width="2"
          value={this.value.productionLineVersion.id}
          onChange={(productionLineVersionId) => {
            const productionLineVersion = this.value.productionLineVersion.productionLine.versions.get(productionLineVersionId) || null
            target.setInput(name, productionLineVersion && { toJS: () => ({
              productionLineVersion: {
                ...productionLineVersion.toJS(),
                productionLine: this.value.productionLineVersion.productionLine.toJS(),
              },
            }) })
          }}
          store={this.value.productionLineVersion.productionLine.versions}
          toOption={(productionLineVersion) => ({
            value: productionLineVersion.id,
            text: `V${productionLineVersion.version}`,
          })}
          disabled={!factory || disabled}
          name="productionLineVersion"
        />
        <TargetSelect noLabel
          data-test-work-station-select
          width="8"
          value={this.value.id}
          onChange={(workStationId) => {
            const workStation = this.value.productionLineVersion.workStations.get(workStationId) || null
            target.setInput(name, workStation && { toJS: () => ({
              ...workStation.toJS(),
              productionLineVersion: {
                ...this.value.productionLineVersion.toJS(),
                productionLine: this.value.productionLineVersion.productionLine.toJS()
              },
            }) })
          }}
          store={this.value.productionLineVersion.workStations}
          toOption={(workStation) => ({
            value: workStation.id,
            text: workStation.name,
          })}
          disabled={!factory || disabled}
          name="workStation"
        />
        <TargetTextInput target={target.workStation} name="defaultFte" disabled />
      </WorkStationGroup>
    )
  }
}
