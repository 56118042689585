import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionLineVersionStore } from './ProductionLineVersion'
import { ArticleTypeStore } from './ArticleType'
import { LeaveCalendarStore } from './LeaveCalendar'
import { LeaveSlotStore } from './LeaveSlot'
import { Factory } from './Factory'


export class ProductionLine extends Model {
  static backendResourceName = 'production_line'

  @observable id = null
  @observable name = ''
  @observable color = 'red'
  @observable type = 'production'

  @observable latestVersion = 1
  @observable newestVersion = 1

  @computed get _newestVersion() {
    return this.versions.find((v) => v.version === this.newestVersion)
  }

  @computed get _latestVersion() {
    return this.versions.find((v) => v.version === this.latestVersion)
  }

  getUrl() {
    return `/assets/production-line/${this.id}/edit`
  }

  relations() {
    return {
      versions: ProductionLineVersionStore,
      articleTypes: ArticleTypeStore,
      leaveCalendars: LeaveCalendarStore,
      leaveSlots: LeaveSlotStore,
      factory: Factory,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)

    delete res.latest_version
    delete res.newest_version
    delete res.utc_offset

    return res
  }
}

export class ProductionLineStore extends Store {
  Model = ProductionLine
  static backendResourceName = 'production_line'
}
