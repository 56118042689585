import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable, action, computed } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Modal, Header, Form, Icon, Popup, Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components'
import { theme } from 'styles'
import { StepContainer } from 'container/Process/Edit'
import { lighten } from 'polished'
import { IconButton, RightDivider, ScrollModal, TargetTextInput, TargetNumberInput } from '@code-yellow/spider';
import { targetDescription, targetSubDescription } from './TargetInfoModal/TargetDescription';

// components
import { stepIcon } from 'component/Steps'
import ModalCornerIcon from 'component/ModalCornerIcon'
import Steps from 'component/Steps'
import PdfModal from 'component/PdfModal';
import PrintersList from 'component/PrinterHelpers/PrintersList'
import DetailsInfo, { HistoryIconButton } from 'component/TargetInfoModal/DetailsInfo';
import QuantityField from 'container/Perform/Step/Form/QuantityField'
import HistoryFeed from 'component/History/HistoryFeed'
// end components

// helpers
import { DATETIME_FORMAT, onEnter } from 'helpers'
import sortSteps from 'helpers/sortSteps'
import { showSaveNotification } from 'helpers/notification'
import { print, printPdf, getPrinters, getPrinterType } from 'helpers/print'
// end helpers

// stores
import { Batch } from 'store/Batch'
import { Step } from 'store/Step'
import { Performance } from 'store/Performance'
import { RELATED_LINK_RELATIONS } from 'store/Batch'
import { HistoryStore } from 'store/History'
// end stores

const LAST_USED_KEY = 'last-used-document-printer'

const NoneMessage = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
`

const Nesting = styled.div`
  border-left: 0.5em solid rgba(34, 36, 38, 0.15);
  margin-left: 2.75em;
  padding-left: 0.5em;
`

const PerformanceIconButton = styled(Icon)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5em !important;
  &:hover {
    color: rgba(0, 0, 0, 0.75);
  }
`



const ScaledForm = styled(Form)`
  max-width: ${({ scale }) => 1000 * scale}px !important;
  margin: 0 auto;
  &.ui.form {
    font-size: ${({ scale }) => scale}rem;
    > label {
      font-size: ${({ scale }) => 0.9 * scale}rem;
    }
  }
`


const PerformancesContainer = styled.div`
  ${({ top }) =>
    top
      ? `
        margin-bottom: 0.75em;
    `
      : `
        margin-left: 2em;
        &::before {
            display: block;
            content: '';
            position: absolute;
            background-color: ${theme.primaryColor};
            left: -1em;
            top: -1.5em;
            width: ${Math.hypot(2, 2.5)}em;
            height: 0.5em;
            transform-origin: 0 50%;
            transform: rotate(${Math.atan2(2.5, 2)}rad);
            z-index: 0;
        }
    `}
  position: relative;
`

const PerformancesBg = styled.div`
  position: absolute;
  left: 0.75em;
  top: 1em;
  width: 0.5em;
  height: calc(100% - 2em);
  border-radius: 0.25em;
  background-color: ${theme.primaryColor};
  ${({ bottom }) =>
    bottom
      ? `
        border-bottom: ${bottom * 2.5 - 2.25}em solid #90A0A0;
    `
      : ''}
  z-index: 0;
`

const PerformanceContainer = styled.div`
  height: 2.5em;
  display: flex;
  align-items: center;
  > .script-type {
    margin-left: 1em !important;
  }
`

const PerformanceIcon = styled.div`
  flex: 0 0 auto;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background-color: ${({ color = theme.primaryColor }) => color};
  color: #fff;
  text-align: center;
  line-height: 2em;
  > i.icon {
    margin: 0 !important;
  }
  margin-right: 0.75em;
  position: relative;
  z-index: 5;
`





@observer
class PerformanceInfo extends Component {
  static propTypes = {
    performance: PropTypes.instanceOf(Performance).isRequired,
    batch: PropTypes.instanceOf(Batch).isRequired,
    step: PropTypes.instanceOf(Step).isRequired,
    onTargetSelect: PropTypes.func,
    includeReset: PropTypes.bool.isRequired,
    resettable: PropTypes.bool.isRequired,
    afterReset: PropTypes.func.isRequired,
  }

  constructor(...args) {
    super(...args)

    this.toggleOpen = this.toggleOpen.bind(this)
    this.performReprint = this.performReprint.bind(this)
  }

  @observable open = false
  @observable reprint = null

  toggleOpen() {
    this.open = !this.open
  }

  async performReprint() {
    const promises = []
    const { performance } = this.props;

    if (performance.printedTemplate) {
      promises.push(print(this.reprint.printer, performance.printedTemplate, { copies: this.reprint.quantity }))
    }
    if (performance.printedDocument) {
      promises.push(printPdf(this.reprint.printer, performance.printedDocument, { copies: this.reprint.quantity }))
    }

    await Promise.all(promises)
    this.reprint = null
  }

  render() {
    const { performance, batch, step, onTargetSelect, includeReset, resettable, afterReset } = this.props

    let reprintFormContent = null
    if (this.reprint === null) {
      reprintFormContent = null
    } else if (this.reprint.printers === null) {
      reprintFormContent = <Dimmer active inverted>
        <Loader size="big" />
      </Dimmer>
    } else if (this.reprint.printers.length === 0) {
      reprintFormContent = <>
        {t('workStation.production.performModal.printStep.noPrintersAvailable')}
        <IconButton name="refresh" onClick={async () => {
          this.reprint.printers = (await getPrinters()).filter((printer) => !['altec', 'zebra'].includes(printer))
        }} />
      </>
    } else {
      reprintFormContent = <PrintersList
        printers={this.reprint.printers}
        onSelect={(printer) => this.reprint.printer = printer}
      />
    }

    return (
      <React.Fragment>
        <PerformanceContainer data-test-performance={performance.id}>
          <PerformanceIcon>
            <Icon name={step ? stepIcon(step) : 'times'} />
          </PerformanceIcon>
          {t('batch.field.performances.description', {
            step: step ? step.label : 'No Step Found',
            operator: performance.operator.fullName,
            createdAt: performance.createdAt.format(DATETIME_FORMAT),
          })}
          {(performance.printedTemplate || performance.printedDocument) && (
            <Form.Group widths="equal">
              <PerformanceIconButton data-test-reprint-button name="print" onClick={async () => {
                const reprint = { quantity: 1 }
                let printerType = null
                if (performance.printedDocument) {
                  printerType = 'document'
                } else if (step.type === 'split') {
                  printerType = step.splitStep.printer
                } else if (step.type === 'print') {
                  printerType = step.printStep.labelPrinter
                }
                reprint.printers = (await getPrinters()).filter((printer) => getPrinterType(printer) === printerType)
                if (reprint.printers.length === 1) {
                  reprint.printer = reprint.printers[0]
                } else {
                  const lastUsed = localStorage.getItem(LAST_USED_KEY)
                  if (lastUsed !== null && reprint.printers.includes(lastUsed)) {
                    reprint.printer = lastUsed
                  } else {
                    reprint.printer = null
                  }
                }
                this.reprint = reprint
              }} />
              {performance.printedDocument && (
                <PdfModal
                  title={t('batch.field.performances.printedDocument')}
                  url={performance.printedDocument}
                  trigger={<span>
                    <Popup
                      trigger={<PerformanceIconButton name="eye" data-test-view-printed-document />}
                      content={t('batch.field.performances.printedDocument')}
                    />
                  </span>}
                />
              )}
            </Form.Group>
          )}
          {(performance.details.length > 0 || performance.scannedLoadCarrier !== null) && (
            <PerformanceIconButton name={this.open ? 'chevron up' : 'chevron down'} onClick={this.toggleOpen} />
          )}
          {includeReset && (
            <PerformanceResetModal
              size="small"
              trigger={<PerformanceIconButton data-test-rework-button name="undo" disabled={!resettable} />}
              performance={performance}
              afterReset={afterReset}
            />
          )}
        </PerformanceContainer>
        {this.open && (
          <Nesting>
            <DetailsInfo
              details={performance.details}
              onTargetSelect={onTargetSelect}
              loadCarrier={performance.scannedLoadCarrier}
              batch={batch}
              step={step}
            />
          </Nesting>
        )}
        {this.reprint !== null && (
          <Modal data-test-reprint-modal open closeIcon size="small" onClose={() => this.reprint = null} closeOnDimmerClick={false}>
            <Modal.Header>{t('targetInfoModal.reprint.title')}</Modal.Header>
            <Modal.Content>
              <Form>
                {this.reprint.printers !== undefined && (
                  <Form.Field>
                    <label>{t('workStation.production.performModal.printStep.selectPrinter')}</label>
                    {reprintFormContent}
                  </Form.Field>
                )}
                <TargetNumberInput autoFocus
                  label={t('targetInfoModal.reprint.quantity')}
                  value={this.reprint.quantity === null ? '' : this.reprint.quantity.toString()}
                  onChange={(quantity) => this.reprint.quantity = quantity === '' ? null : parseInt(quantity)}
                  onKeyPress={onEnter(() => {
                    if (this.reprint.quantity !== null && this.reprint.quantity !== 0) {
                      this.performReprint()
                    }
                  })}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <RightDivider />
              <Button primary
                icon="print"
                content={t('targetInfoModal.reprint.printButton')}
                disabled={this.reprint.quantity === null || this.reprint.quantity === 0}
                onClick={this.performReprint}
              />
            </Modal.Actions>
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

@observer
class PerformanceResetModal extends Component {
  static propTypes = {
    performance: PropTypes.instanceOf(Performance).isRequired,
    trigger: PropTypes.node.isRequired,
    afterReset: PropTypes.func,
  }

  static defaultProps = {
    afterReset: (res) => Promise.resolve(res),
  }

  constructor(...args) {
    super(...args)
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onChangeReason = this.onChangeReason.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  @observable open = false
  @observable reason = ''

  onOpen() {
    this.open = true
  }

  onClose() {
    this.open = false
  }

  onChangeReason(value) {
    this.reason = value
  }

  onSubmit() {
    const { performance, afterReset } = this.props
    return performance.reset(this.reason).then((res) => {
      this.onClose()
      return afterReset(res)
    })
  }

  render() {
    const { performance, trigger, afterReset, ...props } = this.props

    return (
      <Modal
        closeIcon
        data-test-performance-reset-modal
        trigger={trigger}
        open={this.open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        closeOnDimmerClick={false}
        {...props}
      >
        <Modal.Header>{t('performanceResetModal.title')}</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.onSubmit}>
            <TargetTextInput
              autoFocus
              data-test-performance-reset-reason
              label={t('performanceResetModal.reason')}
              name="reason"
              value={this.reason}
              onChange={this.onChangeReason}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            data-test-performance-reset-apply-button
            icon="redo"
            labelPosition="left"
            content={t('performanceResetModal.resetButton')}
            onClick={this.onSubmit}
            disabled={this.reason.length === 0}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

@observer
export class TargetInfo extends Component {
  static propTypes = {
    target: PropTypes.instanceOf(Batch).isRequired,
    lastStep: PropTypes.instanceOf(Step).isRequired,
    onTargetSelect: PropTypes.func,
    steps: PropTypes.object.isRequired,
    hasEditPermissions: PropTypes.bool.isRequired,
    batchHistory: PropTypes.instanceOf(HistoryStore).isRequired,
  }

  @observable showBatchHistory = false;
  @observable remainingQuantity

  constructor(...args) {
    super(...args)

    this.renderPerformanceDetails = this.renderPerformanceDetails.bind(this)
    this.renderPerformance = this.renderPerformance.bind(this)
    this.renderBatch = this.renderBatch.bind(this)
    this.renderEditRemainingQuantity = this.renderEditRemainingQuantity.bind(this)
  }

  componentDidMount() {
    this.remainingQuantity = this.props.target.quantity
  }

  renderPerformanceDetails(performance) {
    const { onTargetSelect, target, hasEditPermissions, batchHistory } = this.props

    return (
      <DetailsInfo
        key={performance.cid}
        batch={target}
        details={performance.details}
        onTargetSelect={onTargetSelect}
        loadCarrier={performance.scannedLoadCarrier}
        hasEditPermissions={hasEditPermissions}
        showHistory={!window.viewStore.isWorkStation}
        onFetchHistory={async () => await batchHistory.fetch()}
      />
    )
  }

  renderPerformance(node, i, nodes) {
    const { target, onTargetSelect } = this.props
    const processVersion = target.productionRequest.processVersion

    if (node.type === 'performance') {
      return (
        <PerformanceInfo
          key={i}
          performance={node.performance}
          batch={target}
          step={processVersion.steps.get(node.performance.step.id)}
          onTargetSelect={onTargetSelect}
          includeReset={window.viewStore.isWorkStation && nodes === this.performances && target.scrapReason === null}
          resettable={
            target.resettable &&
            // For first step for a batch the batch itself would
            // also be scrapped so we have to check if that is
            // allowed
            (i !== 0 || target.scrappable)
          }
          afterReset={() => target.fetch().then(showSaveNotification)}
        />
      )
    } else if (node.type === 'rework') {
      return (
        <React.Fragment key={i}>
          {i === 0 && <PerformanceContainer />}
          <PerformancesContainer>
            <PerformancesBg />
            {node.nodes.map(this.renderPerformance)}
            <PerformanceContainer>
              <PerformanceIcon>
                <Icon name="undo" />
              </PerformanceIcon>
              {node.reason}
            </PerformanceContainer>
          </PerformancesContainer>
        </React.Fragment>
      )
    } else if (node.type === 'subprocesses') {
      return (
        <PerformanceContainer key={i}>
          <PerformanceIcon>
            <Icon name={stepIcon(node.step)} />
          </PerformanceIcon>
          {node.step.label}
        </PerformanceContainer>
      )
    } else if (node.type === 'todo') {
      return (
        <PerformanceContainer key={i}>
          <PerformanceIcon
            color={node.step.id === target.lastStep.nextStep.id ? lighten(0.15, theme.primaryColor) : '#90A0A0'}
          >
            <Icon name={stepIcon(node.step)} />
          </PerformanceIcon>
          {node.step.label}
        </PerformanceContainer>
      )
    } else if (node.type === 'missing') {
      return (
        <PerformanceContainer data-test-missing-performance={node.step.label} key={i}>
          <PerformanceIcon color='#90A0A0'>
            <Icon name={stepIcon(node.step)} />
          </PerformanceIcon>
          {node.step.label}
          <Popup
            trigger={<Icon name="question" />}
            content={'Missing performance!'}
          />
        </PerformanceContainer>
      )
    } else {
      return <React.Fragment key={i} />
    }
  }

  renderBatch(batch) {
    const { onTargetSelect } = this.props

    return <div key={batch.cid}>{targetDescription(batch, onTargetSelect)}</div>
  }

  @computed get performances() {
    const {
      target,
      lastStep,
    } = this.props
    const steps = this.props.steps?.steps

    if (!steps) {
      // No steps, so also no step based performance
      return []
    }

    const performances = []

    let step = 0

    function fixStep(actualStep) {
      if (actualStep < step) {
        // If we are further along than the actual step that means that
        // some steps have had to be reworked
        const nodes = performances.splice(
          performances.findIndex((p) => (
            (p.type === 'performance' && p.performance.step.id === steps[actualStep].id) ||
            (['grow', 'subprocesses'].includes(p.type) && p.step.id === steps[actualStep].id)
          ))
        )
        if (performances.length > 0) {
          performances.push({
            type: 'rework',
            nodes,
            reason: nodes.find(({ type }) => type === 'performance').performance.reworkReason,
          })
        }
      } else if (actualStep > step) {
        // eslint-disable-next-line
        for (const missingStep of steps.slice(step, actualStep)) {
          if (missingStep.type === 'grow') {
            performances.push({ type: 'grow', step: missingStep })
          } else if (missingStep.type === 'subprocesses') {
            performances.push({ type: 'subprocesses', step: missingStep })
          } else {
            // If the actual step is further along than were we are that
            // means that some perforamnces are missing
            // ==> This is started happening at SuperB ..
            // and we still have no clue what is the cause.
            // Meanwhile, not crashing the frontend is a nice start
            // until fruther investigation is done.
            performances.push({ type: 'missing', step: missingStep })
            // throw new Error('Missing performances')
          }
        }
      }
      step = actualStep
    }

    // eslint-disable-next-line
    for (const performance of target.performances.models) {
      const performanceStep = steps.findIndex((s) => s.id === performance.step.id)
      fixStep(performanceStep)

      performances.push({
        type: 'performance',
        performance,
      })
      step += 1
    }

    const targetStep = steps.findIndex((s) => s.id === lastStep.id) + 1
    fixStep(targetStep)

    if (target.scrapReason === null) {
      performances.push(
        ...steps.slice(targetStep).map((step) => ({
          type: 'todo',
          step,
        }))
      )
    }

    return performances
  }

  renderEditRemainingQuantity() {
    const { target, batchHistory } = this.props
    return (
      <>
        <tr>
          <th><label>{t('batch.field.quantityRemaining.label')}</label></th>
          <td>
            <QuantityField data-test-edit-batch-quantity-field
              style={{ 'margin-left': '2.5rem' }}
              quantity={this.remainingQuantity}
              onChange={action(async (value) => {
                this.remainingQuantity = value
              })}
            />
          </td>
          <td>
            <Button data-test-save-quantity
              icon='save'
              onClick={async () => {
                target.quantity = this.remainingQuantity
                await target.save()
                showSaveNotification()
              }}
            />
          </td>
          <td>
            <HistoryIconButton data-test-history-button
              name='history'
              onClick={async () => {
                if (!this.showBatchHistory) {
                  await batchHistory.fetch();
                }
                this.showBatchHistory = !this.showBatchHistory
              }}
            />
          </td>
        </tr>
        {this.showBatchHistory && (
          <HistoryFeed
            object={target}
            history={batchHistory}
            config={[{ tracked_field: 'quantity', show_as_title: true }]}
          />
        )}
      </>
    )
  }

  @computed get todo() {
    return this.performances.filter(({ type }) => type === 'todo').length
  }

  render() {
    const { target, hasEditPermissions } = this.props

    return (
      <React.Fragment>
        {(!target.storageLocation.isNew || !target.warehouse.isNew) && (
        <Form.Group widths="equal" data-test-location-info>
          <Form.Field data-test-batch-warehouse>
            <label>{t('batch.field.warehouse.label')}</label>
            {target.warehouse.isNew ? (
              <NoneMessage>{t('batch.field.warehouse.empty')}</NoneMessage>
            ) : (
              target.warehouse.getLink()
            )}
          </Form.Field>
          <Form.Field data-test-batch-location>
            <label>{t('batch.field.storageLocation.label')}</label>
            {target.storageLocation.isNew ? (
              <NoneMessage>{t('batch.field.storageLocation.empty')}</NoneMessage>
            ) : (
              target.storageLocation.getLink()
            )}
          </Form.Field>
        </Form.Group>
        )}
        {(target.batchUsings.length > 0 || target.batchUseds.length > 0) && (
          <Form.Group widths="equal">
            <Form.Field data-test-batches-using>
              <label>{t('batch.field.batchUsings.label')}</label>
              {target.batchUsings.length === 0 ? (
                <NoneMessage>{t('batch.field.batchUsings.empty')}</NoneMessage>
              ) : (
                target.batchUsings.map((u) => this.renderBatch(u.usedBatch))
              )}
            </Form.Field>
            <Form.Field data-test-batches-used-by>
              <label>{t('batch.field.batchUseds.label')}</label>
              {target.batchUseds.length === 0 ? (
                <NoneMessage>{t('batch.field.batchUseds.empty')}</NoneMessage>
              ) : (
                target.batchUseds.map((u) => this.renderBatch(u.usingBatch))
              )}
            </Form.Field>
          </Form.Group>
        )}
        {hasEditPermissions && this.renderEditRemainingQuantity()}
        {target.performances.models.some((p) => p.details.length !== 0 || p.scannedLoadCarrier) && (
          <Form.Field>
            <label>{t('batch.field.details.label')}</label>
            {
              this.performances
                .filter(({ type }) => type === 'performance')
                .map(({ performance }) => performance)
                .map(this.renderPerformanceDetails)
            }
          </Form.Field>
        )}
        <Form.Field>
          <label data-test-performances>{t('batch.field.performances.label')}</label>
          {target.performances.length === 0 ? (
            <NoneMessage>{t('batch.field.performances.empty')}</NoneMessage>
          ) : (
            <PerformancesContainer top>
              {this.performances.length > 0 && Array.isArray(this.performances[0]) && <PerformanceContainer />}
              <PerformancesBg bottom={this.todo} />
              {this.performances.map(this.renderPerformance)}
              {target.scrapReason !== null && (
                <PerformanceContainer data-test-performance-scrapped>
                  <PerformanceIcon>
                    <Icon name="delete" />
                  </PerformanceIcon>
                  {t('targetInfoModal.scrapped', { reason: target.scrapReason })}
                </PerformanceContainer>
              )}
            </PerformancesContainer>
          )}
        </Form.Field>
      </React.Fragment>
    )
  }
}

function findSteps({ branches, steps }, step) {
  const index = steps.findIndex((s) => s.id === step.id)

  if (index === -1) {
    // eslint-disable-next-line
    for (const branch of branches) {
      const steps = findSteps(branch, step)
      if (steps !== null) {
        return steps
      }
    }
    return null
  }

  let start = index
  while (start > 0 && steps[start].type !== 'split' && steps[start - 1].type !== 'multiplier') {
    start--
  }

  let end = index + 1
  while (end < steps.length && !['multiplier', 'split'].includes(steps[end].type)) {
    end++
  }
  // Split is inclusive
  // if (end < steps.length && steps[end].type === 'split') {
  //   end++
  // }

  return {
    branches: [],
    steps: steps.slice(start, end),
  }
}

/**
 * Shows info for a specific batch. Refetches batch with correct relations.
 */
@observer
export default class TargetInfoModal extends Component {
  static propTypes = {
    /**
     * Batch to display info of. A mirror batch will be created and fetched,
     * so you don't have to worry about relations.
     */
    target: PropTypes.instanceOf(Batch).isRequired,

    trigger: PropTypes.node,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,

    scale: PropTypes.number,

    canSelectStep: PropTypes.func,
    onSelectStep: PropTypes.func,
  }

  static defaultProps = {
    onOpen: () => { },
    onClose: () => { },

    scale: 1,
  }

  @observable open = false

  /**
   * Show info about batch, marked current. You can navigate through the
   * different subprocesses?
   *
   * @type {Batch}
   */
  @observable current = null
  @observable backStack = []
  @observable forwardStack = []
  @observable batchHistory = new HistoryStore({ relations: ['user', 'changes'] });

  constructor(...args) {
    super(...args)

    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onMount = this.onMount.bind(this)
    this.onUnmount = this.onUnmount.bind(this)
    this.goTo = this.goTo.bind(this)
    this.goBack = this.goBack.bind(this)
    this.goForward = this.goForward.bind(this)

    if (this.props.open) {
      this.onOpen()
    }
  }

  goTo(target) {
    target = new Batch(
      { id: target.id },
      {
        relations: [
          'batchType.articleType',
          'lastStep.nextStep',
          'componentBatches.batchUsings.usedBatch',
          'componentBatches.lastStep',
          'componentBatches.batchType.articleType',
          'batchUseds.usingBatch.batchType.articleType', // Parents
          'batchUsings.usedBatch.batchType.articleType', // Children
          'performances.operator',
          'performances.step',
          'performances.details.field.metafield',
          'performances.details.metavalue',
          'performances.details.images',
          'performances.details.materials.billOfMaterialItem.articleType',
          'performances.details.components.batchUsings.usedBatch.batchType.articleType',
          'performances.details.storageLocation.warehouse',
          'performances.scannedLoadCarrier',
          'storageLocation',
          'warehouse',
          'productionRequest.productionOrder.materialPlan.items.articleType',
          'productionRequest.processVersion.steps.formStep',
          'productionRequest.processVersion.steps.multiplierStep',
          'productionRequest.processVersion.steps.splitStep',
          'productionRequest.processVersion.steps.printStep',
          'productionRequest.processVersion.steps.nextStep',
          'productionRequest.processVersion.batchType.articleType.warehouses.warehouse',
          'productionRequest.processVersion.batchType.articleType.storageLocations.storageLocation.warehouse',

          ...RELATED_LINK_RELATIONS,
        ],
      }
    )
    target.setFetchParams({ include_annotations: '*,resettable,scrappable' })

    target.fetch().then(
      action(() => {
        if (this.current !== null) {
          this.backStack.push(this.current)
        }
        this.current = target
        this.batchHistory.url = target.url + 'history/';
        this.batchHistory.fetch()
        this.forwardStack = []
      })
    )
  }

  @computed get canGoBack() {
    return this.backStack.length > 0
  }

  @action goBack() {
    this.forwardStack.push(this.current)
    this.current = this.backStack.pop()
  }

  @computed get canGoForward() {
    return this.forwardStack.length > 0
  }

  @computed get steps() {
    if (this.current === null) {
      return null
    }
    const processVersion = this.current.productionRequest.processVersion

    const sortedSteps = sortSteps(processVersion.steps)
    return findSteps(sortedSteps, this.current.lastStep)
  }

  @action goForward() {
    this.backStack.push(this.current)
    this.current = this.forwardStack.pop()
  }

  @action onOpen() {
    const { onOpen } = this.props

    this.open = true

    onOpen()
  }

  @action onClose() {
    const { onClose } = this.props

    this.open = false

    onClose()
  }

  @action onMount() {
    const { target } = this.props

    this.goTo(target)
  }

  @action onUnmount() {
    this.current = null
    this.backStack = []
    this.forwardStack = []
  }

  @computed get lastStep() {
    if (this.steps === null) {
      return null
    }

    const { steps } = this.steps
    let index = steps.findIndex((step) => step.id === this.current.lastStep.id)

    while (index + 1 < steps.length) {
      const startIndex = index;

      if (steps[index + 1].type === 'subprocesses') {
        if (this.current.productionRequest.subrequestsFinished) {
          index += 1;
        }
      }

      if (index === startIndex) {
        break
      }
    }

    return steps[index]
  }

  @computed get hasEditPermissions() {
    return window.viewStore.currentUser.hasPerformanceDetailsScope(this.current.productionRequest.processVersion.batchType.articleType.id)
  }

  render() {
    const { open, trigger, scale, canSelectStep, onSelectStep } = this.props
    const processVersion = this.current && this.current.productionRequest.processVersion

    return (
      <ScrollModal
        closeIcon
        data-test-target-info-modal
        trigger={trigger}
        open={open === undefined ? this.open : open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        onMount={this.onMount}
        onUnmount={this.onUnmount}
        size="fullscreen"
      >
        <ScrollModal.Header>
          <Header as="h1">
            {this.current && (
              <React.Fragment>
                {targetDescription(this.current, null, true)}
                {targetSubDescription(this.current)}
              </React.Fragment>
            )}
          </Header>
        </ScrollModal.Header>
        <ModalCornerIcon index={2} name="arrow left" disabled={!this.canGoBack} onClick={this.goBack} />
        <ModalCornerIcon name="arrow right" disabled={!this.canGoForward} onClick={this.goForward} />
        {this.current && this.steps && (
          <ScrollModal.Content fixed noPadding>
            <StepContainer>
              <Steps
                disableAfterSelected
                scale={scale}
                steps={processVersion.steps}
                tree={this.steps}
                selected={processVersion.steps.get(this.lastStep.nextStep.id)}
                canSelect={canSelectStep}
                onSelect={onSelectStep}
              />
            </StepContainer>
          </ScrollModal.Content>
        )}
        <ScrollModal.Content>
          <ScaledForm scale={scale}>
            {this.current && (
              <TargetInfo
                target={this.current}
                lastStep={this.lastStep}
                onTargetSelect={this.goTo}
                steps={this.steps}
                hasEditPermissions={this.hasEditPermissions}
                batchHistory={this.batchHistory}
              />
            )}
          </ScaledForm>
        </ScrollModal.Content>
      </ScrollModal>
    )
  }
}
