import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ProductionRequestStore } from './ProductionRequest'
import { BatchType } from './BatchType'
import { StepStore } from './Step'
import { User } from './User'
import { Factory } from './Factory'

export class ProcessVersion extends Model {
  static backendResourceName = 'process_version'

  @observable id = null
  @observable version = 1
  @observable draft = true

  @observable newest = false
  @observable latest = true

  @observable workMinutesPerUnit = 0
  @observable workMinutesPerOrder = 0
  @observable totalFte = 0
  @observable totalSetupMinutes = 0
  @observable totalDelayMinutes = 0

  @observable createdAt = null
  @observable updatedAt = null
  @observable finalizedAt = null

  @observable hasStorageLocationField = null
  @observable finalBatchSize = null

  getUrl() {
    return `/assets/process/${this.process.id}/version/${this.version}/edit`
  }

  relations() {
    return {
      productionRequests: ProductionRequestStore,
      factory: Factory,
      steps: StepStore,
      batchType: BatchType,
      updatedBy: User,
      finalizedBy: User,
    }
  }

  casts() {
    return {
      createdAt: Casts.datetime,
      updatedAt: Casts.datetime,
      finalizedAt: Casts.datetime,
    }
  }

  finalize(rootModel) {
    return this.wrapPendingRequestCount(
      this.api.post(`${this.url}finalize/`).catch((err) => {
        if (err.response) {
          const valErrors = this.api.parseBackendValidationErrors(err.response)
          if (valErrors) {
            (rootModel || this).parseValidationErrors(valErrors)
          }
        }
        throw err
      })
    )
  }

  nextVersion() {
    return this.wrapPendingRequestCount(this.api.post(`${this.url}next_version/`))
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    delete res.newest
    delete res.latest
    delete res.work_minutes
    delete res.total_fte
    return res
  }
}

export class ProcessVersionStore extends Store {
  Model = ProcessVersion
  static backendResourceName = 'process_version'

  @computed get latest() {
    return this.models.find((pv) => pv.latest)
  }

  @computed get newest() {
    return this.models.find((pv) => pv.newest)
  }
}
